import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "field",
    "days",
    "frequency",
    "quantity",
    "customFrequency",
    "observations",
    "posology",
    "submit",
    "id",
    "type",
    "medicationForm",
    "productForm",
    "tooltip",
    "numberOfPackages",
    "toggleNumberOfPackages",
  ];

  connect() {
    this.name = "";
  }

  input(event) {
    const nameElement = event.currentTarget.querySelector("#name");
    if (nameElement) {
      this.name = nameElement.textContent;
      this.idTarget.value =
        event.currentTarget.querySelector("#id").textContent;
      this.typeTarget.value =
        event.currentTarget.querySelector("#type").textContent;
      this.numberOfPackagesTarget.show =
        event.currentTarget.querySelector("#show_number_of_packages")
          .textContent === "true";
      const periodic_frequency = document
        .querySelector("#custom_frequency")
        .classList.contains("hidden");
      this.numberOfPackagesTarget.withheld =
        event.currentTarget.querySelector("#withheld").textContent === "true";
      if (this.numberOfPackagesTarget.withheld) {
        if (
          this.numberOfPackagesTarget.show &&
          this.toggleNumberOfPackagesTarget.classList.contains("hidden")
        ) {
          this.toggleNumberOfPackagesTarget.classList.remove("hidden");
        } else if (
          !this.numberOfPackagesTarget.show &&
          !this.toggleNumberOfPackagesTarget.classList.contains("hidden")
        ) {
          this.toggleNumberOfPackagesTarget.classList.add("hidden");
        } else if (
          !this.numberOfPackagesTarget.show &&
          !periodic_frequency &&
          this.toggleNumberOfPackagesTarget.classList.contains("hidden")
        ) {
          this.toggleNumberOfPackagesTarget.classList.remove("hidden");
        }
      } else if (
        !this.numberOfPackagesTarget.withheld &&
        !this.toggleNumberOfPackagesTarget.classList.contains("hidden")
      ) {
        this.toggleNumberOfPackagesTarget.classList.add("hidden");
      }
      this.numberOfPackagesTarget.disabled = false;
      this.posologyTarget.disabled = false;
      this.daysTarget.disabled = false;
      this.frequencyTarget.disabled = false;
      this.quantityTarget.disabled = false;
      this.customFrequencyTarget.disabled = false;
      this.observationsTarget.disabled = false;
    }
  }

  checkContent() {
    if (
      (this.checkValue(this.quantityTarget.value) &&
        this.checkValue(this.frequencyTarget.value) &&
        this.checkValue(this.daysTarget.value) &&
        this.checkValue(this.posologyTarget.value)) ||
      (this.checkValue(this.quantityTarget.value) &&
        this.checkValue(this.customFrequencyTarget.value) &&
        this.checkValue(this.numberOfPackagesTarget.value))
    ) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  checkValue(value) {
    return value !== null && value !== "";
  }

  addHover() {
    if (this.submitTarget.disabled) {
      if (!this.checkValue(this.quantityTarget.value)) {
        this.quantityTarget.parentNode.classList.add("field_with_errors");
      }
      if (!this.checkValue(this.frequencyTarget.value)) {
        this.frequencyTarget.parentNode.classList.add("field_with_errors");
      }
      if (!this.checkValue(this.daysTarget.value)) {
        this.daysTarget.parentNode.classList.add("field_with_errors");
      }
      if (!this.checkValue(this.customFrequencyTarget.value)) {
        this.customFrequencyTarget.parentNode.classList.add(
          "field_with_errors"
        );
      }
      if (!this.checkValue(this.numberOfPackagesTarget.value)) {
        this.numberOfPackagesTarget.parentNode.classList.add(
          "field_with_errors"
        );
      }
      this.tooltipTarget.classList.remove("hidden");
    }
  }

  removeHover() {
    if (this.submitTarget.disabled) {
      if (!this.checkValue(this.quantityTarget.value)) {
        this.quantityTarget.parentNode.classList.remove("field_with_errors");
      }
      if (!this.checkValue(this.frequencyTarget.value)) {
        this.frequencyTarget.parentNode.classList.remove("field_with_errors");
      }
      if (!this.checkValue(this.daysTarget.value)) {
        this.daysTarget.parentNode.classList.remove("field_with_errors");
      }
      if (!this.checkValue(this.customFrequencyTarget.value)) {
        this.customFrequencyTarget.parentNode.classList.remove(
          "field_with_errors"
        );
      }
      if (!this.checkValue(this.numberOfPackagesTarget.value)) {
        this.numberOfPackagesTarget.parentNode.classList.remove(
          "field_with_errors"
        );
      }
      this.tooltipTarget.classList.add("hidden");
    }
  }

  toggleCustom() {
    this.frequencyTarget.value = null;
    this.daysTarget.value = null;
    this.customFrequencyTarget.value = null;
    this.numberOfPackagesTarget.value = null;
    if (
      this.numberOfPackagesTarget.withheld &&
      !this.numberOfPackagesTarget.show
    ) {
      if (this.toggleNumberOfPackagesTarget.classList.contains("hidden")) {
        this.toggleNumberOfPackagesTarget.classList.remove("hidden");
      } else {
        this.toggleNumberOfPackagesTarget.classList.add("hidden");
      }
    }
    this.checkContent();
  }

  toggleForm() {
    if (this.medicationFormTarget.classList.contains("hidden")) {
      this.medicationFormTarget.classList.remove("hidden");
      this.productFormTarget.classList.add("hidden");
    } else {
      this.medicationFormTarget.classList.add("hidden");
      this.productFormTarget.classList.remove("hidden");
    }
  }

  disableForm() {
    this.posologyTarget.disabled = true;
    this.daysTarget.disabled = true;
    this.frequencyTarget.disabled = true;
    this.quantityTarget.disabled = true;
    this.customFrequencyTarget.disabled = true;
    this.numberOfPackagesTarget.disabled = true;
    this.observationsTarget.disabled = true;
    this.submitTarget.disabled = true;
  }

  addDrug() {
    window.dispatchEvent(new CustomEvent("search:clear"));
    this.disableForm();
  }

  clearOnFocus(event) {
    if (event.currentTarget.value === "0") {
      event.currentTarget.value = "";
    }
  }
}
